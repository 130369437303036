<template>
  <div class="main">
    <div class="zj-top">
      <img
        src="https://sudiaoba.oss-cn-beijing.aliyuncs.com/assets/images/zj-top.png"
      />

      <div class="text-box">
        <span></span>
        <div>100%中奖 好运转不停</div>
        <span></span>
      </div>
      <div class="lottery-title" v-if="prizeNumber || prizeNumber === 0">
        已有{{ prizeNumber }}人参与抽奖
      </div>
    </div>
    <div class="lottery">
      <div
        class="lottery-wheel"
        :style="{
          '-webkit-transition': wheelTransition,
          '-webkit-transform': rotateAngle
        }"
      >
        <div class="prize-item" v-for="(item, index) in prizeList" :key="index">
          <!-- <img :src="`${http}WeChat/Images/lottery/${item.ThumbImageUrl}`" /> -->
          <div
            class="img-box"
            :style="`background-position: ${item.width2}px ${item.heigth2}px;`"
          ></div>
        </div>
      </div>
      <div class="lotterydraw" @click="lotterydraw">
        <div class="lotterydraw-top">立即</div>
        <div>抽奖</div>
      </div>
    </div>
    <!-- 抽奖机会剩余 -->
    <div class="prize-opportunity">
      <div class="prize-box-fa">
        <div class="prize-box">你还有{{ prizeOpportunity }}次抽奖机会</div>
      </div>
      <img class="prize-img" src="@/assets/images/prize-opportunity.png" />
    </div>
    <!-- 中奖名单 -->
    <div class="winning-tabar">
      <div
        :class="['winning-box', { 'winning-box-active': winningType === 1 }]"
        @click="winningType = 1"
      >
        我的中奖记录
      </div>
      <div
        :class="['winning-box', { 'winning-box-active': winningType === 2 }]"
        @click="winningType = 2"
      >
        中奖人员名单
      </div>
    </div>
    <div class="winning-list" v-if="winningType === 1">
      <!-- <div class="winning-title">
        <img src="@/assets/images/xbzq/mask.png" />
        <span class="abs-title">中奖名单</span>
      </div> -->
      <div class="list-box" @scroll="scroll">
        <div
          class="list-item list-item2"
          v-for="(item, index) in resultsList"
          :key="index"
        >
          <div class="list-left">{{ item.CreateDate | fromDate }}</div>
          <div class="list-right">
            {{
              item.AwardName === "谢谢参与"
                ? item.AwardName
                : "恭喜你获得" + item.AwardName
            }}
          </div>
        </div>
        <div class="list-box-tips" v-if="resultsList.length == 0">
          暂无抽奖记录
        </div>
      </div>
    </div>
    <div class="winning-list" v-else>
      <!-- <div class="winning-title">
        <img src="@/assets/images/xbzq/mask.png" />
        <span class="abs-title">中奖名单</span>
      </div> -->
      <div class="list-box">
        <div
          class="list-item"
          v-for="(item, index) in LatestResults"
          :key="index"
        >
          <div class="list-left">{{ item.Dateline | fromDate }}</div>
          <div class="list-content">
            {{ item.AwardName }}
          </div>
          <div class="list-right">{{ item.MemberKey }}</div>
        </div>
        <div class="list-box-tips" v-if="LatestResults.length == 0">
          暂无抽奖记录
        </div>
      </div>
    </div>
    <!-- 横桥 -->
    <div class="cross-bridge">
      <div></div>
      <div></div>
    </div>
    <div class="Gift-list">
      <div class="winning-title">
        <img src="@/assets/images/xbzq/mask.png" />
        <span class="abs-title">礼品介绍</span>
      </div>
      <div class="card-box">
        <div
          class="card-item"
          v-for="(item, index) in this.prizeList"
          :key="index"
        >
          <!-- <img :src="`${http}WeChat/Images/lottery/${item.ThumbImageUrl}`" /> -->

          <div
            class="img-box"
            :style="`background-position: ${item.width}px ${item.heigth}px;`"
          ></div>
        </div>
      </div>
      <div class="winning-title">
        <img src="@/assets/images/xbzq/mask.png" />
        <span class="abs-title">活动介绍</span>
      </div>
      <div class="tips-box">
        2025年1月1日起，新版速调吧推出“速调吧幸运抽奖100%中奖”大转盘活动，让会员在回答问卷的同时能获得更多抽奖机会，100%获奖。
        <p>
          奖品设置：
          速调吧会不定期更新奖品品类，本期设置，1金币、10金币、50金币、100金币、500金币、5000金币
        </p>
        <p>如何获得抽奖机会：</p>
        速调吧的抽奖机会从两个途径获得：
        <br />①、答卷冲关随机获得抽奖机会 <br />
        ②、参与速调吧公众号或者官网推出的活动随机赠送抽奖机会。
        <br />③、给速调吧提出宝贵建议一旦采用获得更多抽奖机会。
        <p>注意：本活动只在新版速调吧中推出</p>
      </div>
    </div>
    <div class="content-bottom">
      <img src="@/assets/images/xbzq/cjbotom.png" />
    </div>
  </div>
</template>

<script>
import { Message } from "element-ui";
export default {
  filters: {
    fromDate(data) {
      return data.slice(0, 16);
    }
  },
  data() {
    return {
      LuckyIndex: 0, // 回馈下标
      wheelStartRotateDegree: 0, // 转盘初始化角度
      wheelTransition: "transform  5s ease-in-out", // 初始化转盘
      rotateAngle: 0, // 转盘要转的角度
      rotating: false, //转盘完成在次开始判断
      prizeList: [], //转盘数据
      lotteryname: "", //中奖奖品名称
      lotteryshow: false,

      resultsList: [],
      LatestResults: [],
      http: process.env.VUE_APP_SERVER,

      lotteryID: null,
      Remark: null,

      prizeNumber: null, //抽奖人数
      prizeOpportunity: 0, //抽奖机会
      winningType: 1,
      flag: false,
      page: 1
    };
  },
  methods: {
    async lotterydraw() {
      if (!this.rotating) {
        await this.setLottery();
        // 转盘方法
        if (this.lotteryID) {
          this.gameRotate(this.lotteryID);
        }
      }
    },
    // 转盘逻辑
    gameRotate(lotteryID) {
      this.rotating = true;
      const len = this.prizeList.length;

      for (let i = 0; i < len; i++) {
        if (this.prizeList[i].ID === lotteryID) {
          this.LuckyIndex = i;
        }
      }
      console.log("index", this.LuckyIndex);

      // const result = [0, 36, 72, 108, 144, 180, 216, 252, 288, 324];
      const result = [0, 324, 288, 252, 216, 180, 144, 108, 72, 36];
      let FIXED_TIMES = 5;
      const addDegree = (FIXED_TIMES + 1) * 360;
      let rotateAngleNum =
        this.wheelStartRotateDegree +
        addDegree +
        result[this.LuckyIndex] -
        (this.wheelStartRotateDegree % 360);
      this.wheelStartRotateDegree = rotateAngleNum; // 转盘初始化角度
      this.rotateAngle = `rotate(${rotateAngleNum}deg)`; //转盘要转的角度
      console.log("1111", rotateAngleNum % 360);
      setTimeout(() => {
        this.gameover();
      }, FIXED_TIMES * 1000 + 600); // 延时，保证转盘转完
    },
    // 转盘转完可再次转
    gameover() {
      this.rotating = false;
      //   中奖奖品
      this.lotteryshow = true;
      this.lotteryID = null;
      Message.success(this.Remark);
      this.getLatestChances();
      this.getLatestResults2();
    },
    //获取奖项列表
    async getAwards() {
      const data = await this.$http.get("/api/v1/Awards");
      let response = JSON.parse(data.request.response);
      if (response.status === 200) {
        console.log("data", response);
        this.prizeList = response.response.map((res, index) => {
          return {
            ...res, // 展开原始对象以保持原有属性
            width: index < 5 ? -25 + index * -135 : -25 + (index - 5) * -135, // 添加新属性
            heigth: index < 5 ? -12 : -112,
            width2:
              (index < 5 ? -25 + index * -135 : -25 + (index - 5) * -135) *
              0.75, // 添加新属性
            heigth2: (index < 5 ? -12 : -112) * 0.75
          };
        });

        this.prizeList[1].width2 = -125;
        this.prizeList[2].width2 = -123;
        this.prizeList[3].width2 = -342;
        this.prizeList[4].width2 = -451;
        this.prizeList[6].width2 = -122;
        this.prizeList[7].width2 = -231;
        this.prizeList[8].width2 = -343;
        this.prizeList[9].width2 = -446;
      }
    },
    //我的中奖记录
    async getResults() {
      this.flag = false;
      const data = await this.$http.get(
        `/api/v1/Lottery/Results?page=${this.page}&size=10`
      );
      let response = JSON.parse(data.request.response);
      if (response.status === 200) {
        console.log("我的中奖记录", response.response.data);
        this.resultsList = this.resultsList.concat(response.response.data);
        if (response.response.data.length !== 0) {
          this.flag = true;
          this.page++;
        }
      }
    },
    scroll(event) {
      const { scrollTop, clientHeight, scrollHeight } = event.target;
      if (scrollHeight - (scrollTop + clientHeight) <= 0) {
        console.log("1111", scrollHeight - (scrollTop + clientHeight));
        if (this.flag) {
          this.getResults();
        }
      }
    },
    // 最近中奖记录
    async getLatestResults() {
      const data = await this.$http.get("/api/v1/Lottery/LatestResults");
      let response = JSON.parse(data.request.response);
      if (response.status === 200) {
        console.log("最近中奖记录", response.response);
        this.LatestResults = response.response;
      }
    },
    //最近抽奖人数
    async getLatestResults2() {
      const data = await this.$http.get("/api/v1/Lottery/LatestResults");
      let response = JSON.parse(data.request.response);
      if (response.status === 200) {
        this.prizeNumber = response.extend;
      }
    },

    // 抽奖机会
    async getLatestChances() {
      const data = await this.$http.get(
        "/api/v1/Lottery/Chance/Stats?used=false"
      );
      let response = JSON.parse(data.request.response);
      if (response.status === 200) {
        this.prizeOpportunity = response.response;
      }
    },

    //抽奖
    async setLottery() {
      const data = await this.$http.post("/api/v1/Lottery");
      let response = JSON.parse(data.request.response);
      if (response.status === 200 && response.response) {
        this.lotteryID = response.response.ID;
        this.Remark = response.response.Remark;
      } else {
        Message.warning(response.msg);
      }
    }
  },
  async created() {
    this.getAwards();
    this.getLatestResults();
    this.getLatestResults2();
    this.getLatestChances();
    this.getResults();
  },
  mounted() {}
};
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  position: relative;
  background-color: #ff503f;

  .zj-top {
    width: 100%;
    position: relative;

    img {
      width: 100%;
    }

    .text-box {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      width: 755px;
      height: 68px;
      background: radial-gradient(12% 51% at 50% 12%, #fffbf1 0%, #fdd8a3 100%);
      border-radius: 34px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 24px;
      color: #ff1e09;
      font-weight: bolder;
      letter-spacing: 4px;

      span {
        width: 157px;
        height: 0px;
        border: 1px solid;
        border-image: linear-gradient(
            90deg,
            rgba(255, 127, 115, 1),
            rgba(255, 83, 67, 0)
          )
          1 1;
      }

      div {
        margin: 0 45px;
      }
    }

    .lottery-title {
      position: absolute;
      width: 100%;
      text-align: center;
      color: #fff;
      font-size: 22px;
      top: 50%;
      transform: translateY(68px);
    }
  }
}

.lottery {
  position: relative;
  width: 40vw;
  height: 40vw;
  left: 50%;
  top: -105px;
  transform: translateX(-50%);
}

.lottery-wheel {
  width: 40vw;
  height: 40vw;
  margin: auto;
  background: url("https://sudiaoba.oss-cn-beijing.aliyuncs.com/assets/images/lunpan.png")
    0 0 no-repeat;
  background-size: 100% 100%;
  position: relative;
  width: 100%;
  height: 100%;

  // transform: rotate(-72deg);
  .prize-item {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 70px;
    height: 70px;

    img {
      width: 100%;
      height: 100%;
    }
    .img-box {
      width: 100%;
      height: 100%;
      background-image: url("https://sudiaoba.oss-cn-beijing.aliyuncs.com/assets/images/reward-points.png");
      background-size: 540px 168px;
      overflow: hidden;
      background-repeat: no-repeat;
    }
  }

  // 奖品样式
  .prize-item:nth-child(1) {
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }

  .prize-item:nth-child(2) {
    position: absolute;
    top: 25%;
    left: 68%;
    transform: translateX(-50%) translateY(-50%) rotate(36deg);
  }

  .prize-item:nth-child(3) {
    position: absolute;
    top: 40.5%;
    left: 79%;
    transform: translateX(-50%) translateY(-50%) rotate(72deg);
  }

  .prize-item:nth-child(4) {
    position: absolute;
    bottom: 40.5%;
    left: 79%;
    transform: translateX(-50%) translateY(50%) rotate(108deg);
  }

  .prize-item:nth-child(5) {
    position: absolute;
    bottom: 25%;
    left: 68%;
    transform: translateX(-50%) translateY(50%) rotate(144deg);
  }

  .prize-item:nth-child(6) {
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }

  .prize-item:nth-child(7) {
    position: absolute;
    bottom: 25%;
    right: 68%;
    transform: translateX(50%) translateY(50%) rotate(216deg);
  }

  .prize-item:nth-child(8) {
    position: absolute;
    bottom: 40.5%;
    right: 79%;
    transform: translateX(50%) translateY(50%) rotate(252deg);
  }

  .prize-item:nth-child(9) {
    position: absolute;
    top: 40.5%;
    right: 79%;
    transform: translateX(50%) translateY(-50%) rotate(288deg);
  }

  .prize-item:nth-child(10) {
    position: absolute;
    top: 25%;
    right: 68%;
    transform: translateX(50%) translateY(-50%) rotate(324deg);
  }
}

.lotterydraw {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 5;
  width: 90px;
  height: 105px;
  background: url("../../../assets//images/xbzq/zqan.png") 0 0 no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: #fff;
  letter-spacing: 1px;
  cursor: pointer;

  .lotterydraw-top {
    margin-top: 10px;
  }
}

//抽奖机会
.prize-opportunity {
  position: relative;
  top: -70px;

  .prize-img {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: 559px;
    height: 112px;
  }

  .prize-box-fa {
    position: relative;
    top: 40px;
    left: 50%;
    transform: translateX(-50%);
    width: 498px;
    height: 83px;
    background: #ff9c32;
    border-radius: 41.5px;
    z-index: 1;
  }

  .prize-box {
    width: 499px;
    height: 74px;
    text-align: center;
    line-height: 74px;
    color: #d32f17;
    font-size: 24px;
    background: #ffc932;
    box-shadow: inset 0px 5px 30px 0px #ffffff;
    border-radius: 37px;
    border-image: linear-gradient(
        200deg,
        rgba(255, 148.000006377697, 49.00000087916851, 1),
        rgba(255, 255, 255, 1)
      )
      1 1;
  }
}

.winning-tabar {
  width: 904px;
  display: flex;
  justify-content: center;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  top: 6px;

  .winning-box {
    margin: 0 52px;
    width: 265px;
    height: 49px;
    background: #ff9a0e;
    border-radius: 8px 8px 8px 8px;
    text-align: center;
    line-height: 43px;
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
  }

  .winning-box-active {
    color: #202020;
    background-color: #fff3df;
  }
}

.winning-list {
  width: 904px;
  height: 211px;
  background: #fff3df;
  // box-shadow: inset 4px 4px 4px 0px #ffffff;
  border-radius: 16px 16px 16px 16px;
  // border: 1px solid #ffffff;
  position: relative;
  left: 50%;
  transform: translateX(-50%);

  .winning-title {
    width: 216px;
    height: 37px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 15px;

    img {
      width: 100%;
      height: 100%;
    }

    .abs-title {
      position: absolute;
      font-size: 16px;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      color: #fff;
    }
  }

  .list-box {
    overflow: auto;
    height: 100%;
    width: 100%;
    // margin-top: 10px;
    padding: 5px 0;
    position: relative;

    .list-item {
      display: flex;
      justify-content: center;
      color: #202020;
      font-size: 14px;
      margin: 16px 0;

      .list-left {
        margin-right: 24px;
        width: 200px;
        text-align: center;
      }

      .list-right {
        margin-left: 24px;
        width: 200px;
        text-align: center;
      }

      .list-content {
        width: 200px;
        text-align: center;
      }
    }

    .list-item2 {
      .list-left {
        width: 200px;
        text-align: center;
      }

      .list-right {
        width: 200px;
        text-align: center;
      }
    }

    .list-box-tips {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      color: #202020;
      font-size: 14px;
    }
  }
}

.cross-bridge {
  width: 904px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: space-between;

  div {
    width: 21px;
    height: 33px;
    background: #fff3df;
    box-shadow: inset 0px 4px 9px 0px #ffc563;
    margin: 0 191px;
  }
}

.Gift-list {
  width: 904px;
  padding-bottom: 42px;
  background: #fff3df;
  box-shadow: inset 4px 4px 4px 0px #ffffff;
  border-radius: 16px 16px 16px 16px;
  border: 1px solid #ffffff;
  position: relative;
  left: 50%;
  transform: translateX(-50%);

  .winning-title {
    width: 216px;
    height: 37px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 15px;

    img {
      width: 100%;
      height: 100%;
    }

    .abs-title {
      position: absolute;
      font-size: 16px;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      color: #fff;
    }
  }

  .card-box {
    display: flex;
    // justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 37px;
    padding: 0 36px;

    .card-item {
      width: 120px;
      height: 120px;
      // background: #ffffff;
      overflow: hidden;
      border-radius: 8px 8px 8px 8px;
      margin-bottom: 16px;
      margin-right: calc((100% - 600px) / 4);
      background-color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 80px;
        height: 80px;
      }
      .img-box {
        width: 80px;
        height: 100px;
        background-image: url("https://sudiaoba.oss-cn-beijing.aliyuncs.com/assets/images/reward-points.png");
        background-size: 672px 224px;
        overflow: hidden;
        background-repeat: no-repeat;
      }
    }

    .card-item:nth-child(5n) {
      margin-right: 0;
    }
  }

  .tips-box {
    padding: 0 33px 0 45px;
    font-size: 14px;
    line-height: 20px;
    color: #ff5840;
    margin-top: 22px;

    p {
      margin-top: 20px;
    }
  }
}

.content-bottom {
  padding: 20px 0;
  width: 100%;

  img {
    width: 100%;
  }
}
</style>
